import React, { Component, ReactNode } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import CueCard from './common/molecules/CueCard';
import * as Breakpoints from '../constants/breakpoints';
import GridEvenSplit from './common/atoms/GridEvenSplit';

import * as InternalPropTypes from '../constants/internal-types';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';
import CallToAction from './common/molecules/CallToAction';

const styles = {
  '& .cue-card-grid-pair .cell': {
    paddingRight: '80px',
  },
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '45px',
    paddingBottom: '90px',
    '& .cue-card-grid-pair + .cue-card-grid-pair': {
      marginTop: '80px',
    },
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '30px',
    paddingBottom: '60px',
    '& .cue-card-grid-pair + .cue-card-grid-pair': {
      marginTop: '40px',
    },
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '10px',
    paddingBottom: '20px',
    '& .cue-card-grid-pair .cell': {
      marginTop: '25px',
    },
  },
  '& .link-container': {
    display: 'grid',
    height: '100%',
  },
  '& .link': {
    alignSelf: 'end',
    marginTop: '10px',
    marginLeft: '89px',
  },
};

type CueCardProps = {
  mediaItems?: InternalPropTypes.MediaItem[];
  backgroundColor?: TypeBackgroundColorTypes;
  withLinks?: boolean;
};

class CueCardGrid extends Component<CueCardProps> {
  static defaultProps = {
    mediaItems: [],
    backgroundColor: null,
    withLinks: false,
  };

  render() {
    const { mediaItems, backgroundColor, withLinks } = this.props;

    const items = mediaItems.map((item) => (
      <div className="link-container" key={`cuecard-${item.name}`}>
        <CueCard {...item} />
        {withLinks && item.link && (
          <CallToAction className="link" link={item.link} />
        )}
      </div>
    ));
    const pairs: ReactNode[][] = items.reduce((result, _, index, array) => {
      if (index % 2 === 0) {
        result.push(array.slice(index, index + 2));
      }
      return result;
    }, []);

    const addStyle = backgroundColor ? { background: backgroundColor } : {};
    return (
      <div className="cue-card-grid-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            {pairs.map((pair, idx) => (
              <GridEvenSplit
                /* eslint-disable-next-line react/no-array-index-key */
                key={`grid-${idx}`}
                className="cue-card-grid-pair"
                onRenderCells={pair.map((cellContent) => () => cellContent)}
                snapAtBreakpoint={Breakpoints.Mobile}
              />
            ))}
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default CueCardGrid;
