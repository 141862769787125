import { Component } from 'react';

import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import SectionTopMolecule from './common/molecules/SectionTop';

import * as InternalPropTypes from '../constants/internal-types';
import * as Breakpoints from '../constants/breakpoints';
import {
  BodyTextWidthEnum,
  TypeBodyTextWidthTypes,
  TypeBackgroundColorTypes,
  TypeSEOWeightTypes,
} from '../constants/cms-constants';

const styles = {
  [Breakpoints.DesktopUp.mq]: {
    paddingTop: '130px',
  },
  [Breakpoints.Tablet.mq]: {
    paddingTop: '110px',
  },
  [Breakpoints.Mobile.mq]: {
    paddingTop: '55px',
  },
};

type SectionTopProps = {
  lightHeaderText?: InternalPropTypes.RichText;
  headerText?: InternalPropTypes.RichText;
  bodyText?: InternalPropTypes.RichText;
  link?: InternalPropTypes.Link;
  bodyTextWidth?: TypeBodyTextWidthTypes;
  backgroundColor?: TypeBackgroundColorTypes;
  seoWeight?: TypeSEOWeightTypes;
};

class SectionTop extends Component<SectionTopProps> {
  static defaultProps = {
    lightHeaderText: null,
    headerText: null,
    bodyText: null,
    link: null,
    bodyTextWidth: BodyTextWidthEnum.SHORT,
    backgroundColor: null,
    seoWeight: 'h2',
  };

  render() {
    const { backgroundColor, ...other } = this.props;
    const addStyle = backgroundColor;
    return (
      <div className="section-top-component" css={[styles, addStyle]}>
        <ContentOuterBound>
          <ContentSpacer>
            <SectionTopMolecule {...other} />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default SectionTop;
