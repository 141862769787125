import { Component } from 'react';
import { css } from '@emotion/react';

import TitleTextGroup from './common/molecules/TitleTextGroup';
import TeaserGridMolecule, {
  formatEventArrayAsTeaserItemArray,
} from './common/molecules/TeaserGrid';

import * as InternalPropTypes from '../constants/internal-types';
import ContentOuterBound from './common/atoms/ContentOuterBound';
import ContentSpacer from './common/atoms/ContentSpacer';
import { TypeBackgroundColorTypes } from '../constants/cms-constants';

const styles = css({
  '& .title': {
    marginTop: '19px',
    marginBottom: '35px',
  },
});

type EventPastGridProps = {
  events?: InternalPropTypes.Event[];
  backgroundColor?: TypeBackgroundColorTypes;
  mainHeader?: InternalPropTypes.RichText;
  showNumbering?: boolean;
};

class EventPastGrid extends Component<EventPastGridProps> {
  static defaultProps = {
    events: [],
    backgroundColor: null,
    mainHeader: '',
    showNumbering: false,
  };

  render() {
    const { events, backgroundColor, mainHeader, showNumbering } = this.props;

    const addStyle = backgroundColor ? { background: backgroundColor } : {};

    return (
      <div css={[styles, addStyle]} className="event-past-component">
        <ContentOuterBound>
          <ContentSpacer>
            <TitleTextGroup titleText={mainHeader} />
            <TeaserGridMolecule
              items={formatEventArrayAsTeaserItemArray(events)}
              showNumbering={showNumbering}
            />
          </ContentSpacer>
        </ContentOuterBound>
      </div>
    );
  }
}

export default EventPastGrid;
